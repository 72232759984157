#root {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-image: url('https://static.xiedaimala.com/xdml/image/6e556a51-b8ff-466f-bda6-7d1847e39f2e/2019-12-4-14-4-6.jpg');
  background-size: cover;
  background-position-y: bottom;
  --button-background-color: #222;
  --button-text-color: #fff;
}

.wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
